// src/pages/_app.tsx
import "../styles/globals.css";
import { SessionProvider, useSession } from "next-auth/react";
import type { Session } from "next-auth";
import type { AppType } from "next/app";
import { trpc } from "@/utils/trpc";
import Head from "next/head";
import { Toaster } from "sonner";
import { HighlightInit, H, ErrorBoundary } from "@highlight-run/next/client";
import React, { useEffect } from "react";
import { env } from "~/env.mjs";
import Script from "next/script";

// import { AxiomWebVitals } from "next-axiom";

const MyApp: AppType<{
  session: Session | null;
  disableMetaTags?: boolean;
}> = ({ Component, pageProps: { session, ...pageProps } }) => {
  return (
    <>
      <Script defer data-domain="gigintel.art" src="/js/script.js" />
      {/* <AxiomWebVitals /> */}
      {env.NEXT_PUBLIC_NODE_ENV !== "development" ? (
        <HighlightInit
          projectId={env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID}
          tracingOrigins
          environment={env.NEXT_PUBLIC_NODE_ENV}
          networkRecording={{
            enabled: true,
            recordHeadersAndBody: true,
            urlBlocklist: [],
          }}
        />
      ) : null}
      <ErrorBoundary>
        <SessionProvider session={session}>
          <AuthListener />
          <Head>
            <meta property="og:site_name" content="GigIntel" />
            <meta charSet="UTF-8" />
            <meta name="robots" content="index, follow" />
            <link rel="canonical" href="https://gigintel.art/" />
            {pageProps.disableMetaTags ? null : (
              <>
                <title>GigIntel - Home</title>
                <meta
                  name="description"
                  content="Theatre reviews made by the community, for the community."
                />
                <meta property="og:title" content="GigIntel" />
                <meta
                  property="og:description"
                  content="Theatre reviews made by the community, for the community."
                />
                <meta property="og:image" content="/og-image.png" />
              </>
            )}
            <link rel="icon" href="/favicon.jpg" />
          </Head>
          {/* <ErrorBoundary> */}
          <Component {...pageProps} />
          {/* </ErrorBoundary> */}
          <Toaster />
        </SessionProvider>
      </ErrorBoundary>
    </>
  );
};

const AuthListener: React.FC = () => {
  const session = useSession();

  useEffect(() => {
    if (
      session.status === "authenticated" &&
      session.data.user &&
      session.data.user.email
    ) {
      H.identify(session.data.user.email, {
        id: session.data.user?.id,
        name: session.data.user?.name as string,
        displayName: session.data.user?.displayName as string,
      });
    }
  }, [session.data?.user, session.status]);

  return null;
};

export default trpc.withTRPC(MyApp);
