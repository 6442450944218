import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

export const env = createEnv({
  /*
   * Serverside Environment variables, not available on the client.
   * Will throw if you access these variables on the client.
   */
  server: {
    DATABASE_URL: z.string().url(),
    NODE_ENV: z.enum(["development", "test", "production"]),
    NEXTAUTH_SECRET: z.string(),
    NEXTAUTH_URL: z.preprocess(
      // This makes Vercel deployments not fail if you don't set NEXTAUTH_URL
      // Since NextAuth automatically uses the VERCEL_URL if present.
      (str) => process.env.VERCEL_URL ?? str,
      // VERCEL_URL doesnt include `https` so it cant be validated as a URL
      process.env.VERCEL ? z.string() : z.string().url(),
    ),
    GOOGLE_CLIENT_ID: z.string(),
    GOOGLE_CLIENT_SECRET: z.string(),
    ALGOLIA_APP_ID: z.string(),
    ALGOLIA_API_KEY: z.string(),
    ALOGOLIA_SEARCH_API_KEY: z.string(),
    ALGOLIA_INDEX_PREFIX: z.string(),
    SEND_GRID_API_KEY: z.string(),
    QSTASH_TOKEN: z.string(),
    VERCEL_URL: z.string().optional(),
    APIFY_API_TOKEN: z.string(),
    OPENAI_API_KEY: z.string(),
    AIRTABLE_CLIENT_ID: z.string(),
    AIRTABLE_CLIENT_SECRET: z.string(),
    DOMAIN: z.string().optional(),
  },
  /*
   * Environment variables available on the client (and server).
   *
   * 💡 You'll get type errors if these are not prefixed with NEXT_PUBLIC_.
   */
  client: {
    NEXT_PUBLIC_ALGOLIA_APP_ID: z.string(),
    NEXT_PUBLIC_ALGOLIA_SEARCH_KEY: z.string(),
    NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX: z.string(),
    NEXT_PUBLIC_CONTENTFUL_SPACE_ID: z.string(),
    NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN: z.string(),
    NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID: z.string(),
    NEXT_PUBLIC_NODE_ENV: z
      .enum(["development", "test", "production"])
      .default("development"),
  },
  /*
   * Due to how Next.js bundles environment variables on Edge and Client,
   * we need to manually destructure them to make sure all are included in bundle.
   *
   * 💡 You'll get type errors if not all variables from `server` & `client` are included here.
   */
  runtimeEnv: {
    DATABASE_URL: process.env.DATABASE_URL,
    NODE_ENV: process.env.NODE_ENV,
    NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
    NEXTAUTH_URL: process.env.NEXTAUTH_URL,
    GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
    GOOGLE_CLIENT_SECRET: process.env.GOOGLE_CLIENT_SECRET,
    ALGOLIA_APP_ID: process.env.ALGOLIA_APP_ID,
    ALGOLIA_API_KEY: process.env.ALGOLIA_API_KEY,
    ALOGOLIA_SEARCH_API_KEY: process.env.ALOGOLIA_SEARCH_API_KEY,
    ALGOLIA_INDEX_PREFIX: process.env.ALGOLIA_INDEX_PREFIX,
    SEND_GRID_API_KEY: process.env.SEND_GRID_API_KEY,
    QSTASH_TOKEN: process.env.QSTASH_TOKEN,
    VERCEL_URL: process.env.VERCEL_URL,
    APIFY_API_TOKEN: process.env.APIFY_API_TOKEN,
    OPENAI_API_KEY: process.env.OPENAI_API_KEY,
    AIRTABLE_CLIENT_ID: process.env.AIRTABLE_CLIENT_ID,
    AIRTABLE_CLIENT_SECRET: process.env.AIRTABLE_CLIENT_SECRET,
    DOMAIN: process.env.DOMAIN,

    NEXT_PUBLIC_ALGOLIA_APP_ID: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    NEXT_PUBLIC_ALGOLIA_SEARCH_KEY: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
    NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX:
      process.env.NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX,
    NEXT_PUBLIC_CONTENTFUL_SPACE_ID:
      process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
    NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN:
      process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
    NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID:
      process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID,
    NEXT_PUBLIC_NODE_ENV: process.env.NODE_ENV,
  },
  emptyStringAsUndefined: true,
});
